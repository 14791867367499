<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          AMZ Invoice
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'invoice-list' }" >
          Invoice List
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{$route.params.id}}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'invoice-list' }"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>

    <!-- first floor -->
    <b-row>
      <b-col
        md="2"
        class="ll-p2"
      >
        <b-card
          class="ll-c ll-4"
          header="Invoice No"
          :title="baseInfo.invoiceNo"
        >
        </b-card>
      </b-col>
      <b-col
        md="2"
        class="ll-p2"
      >
        <b-card
          class="ll-c ll-4"
          header="Status"
          :title="baseInfo.status"
        >
        </b-card>
      </b-col>
      <b-col
        md="2"
        class="ll-p2"
      >
        <b-card
          class="ll-c ll-4"
          header="Invoice Date"
          :title="baseInfo.createdAt"
        >
        </b-card>
      </b-col>
      <b-col
        md="2"
        class="ll-p2"
      >
        <b-card
          class="ll-c ll-4"
          header="TKID Qty"
          :title="baseInfo.quantity"
        >
        </b-card>
      </b-col>
      <b-col
        md="2"
      >
        <b-card
          class="ll-c ll-4"
          header="Total Amount"
          :title="baseInfo.totalAmount"
        >
        </b-card>
      </b-col>
    </b-row>
    <!-- second floor -->
    <b-row>
      <b-col
        md="6"
        lg="9"
        class="ll-p2"
      >
      <b-card
        no-body
        class="card-browser-states ll-r ll-6 ll-height"
        >
          <b-card-header>
            <div class="ll-1">
              <b-card-title class="ll-title">Invoice breakdown</b-card-title>
              <b-form-input
                v-model="searchPID"
                placeholder="Enter TKID To Search"
                type="search"
                class="ll-input"
                @input="handleSearch"
              />
            </div>
          </b-card-header>
          <b-card-body class="ll-cb">
            <b-table :items="items" :fields="invoiceFields" :borderless='false' :hover="true">
            </b-table>
            <div class="d-flex justify-content-between flex-wrap ll-page">
              <div class="d-flex align-items-center mb-0">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="size"
                  :options="pageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ totalSize }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-model="page"
                  :total-rows="totalSize"
                  :per-page="size"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </b-card-body>
       </b-card>
      </b-col>
      <b-col
        md="6"
        lg="3"
        class="ll-p2"
      >
        <b-card
          no-body
          class="card-browser-states ll-c ll-6 ll-mrg">
          <b-card-header>
            <b-card-title>Bill To Party</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-form-group
              label="Name"
              label-for="orderId"
              class="ll-bform"
            >
              <b-form-input
                id="orderId1"
                v-model="account.billToName"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Company"
              label-for="orderId"
              class="ll-bform"
            >
              <b-form-input
                id="orderId2"
                v-model="account.billToCompany"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Address"
              label-for="mawbNo"
              class="ll-bform"
            >
              <b-form-input
                id="mawbNo"
                v-model="account.billToAddress1"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="City"
              label-for="cusOrderNo"
              class="ll-bform"
            >
              <b-form-input
                id="cusOrderNo"
                v-model="account.billToCity"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Province"
              label-for="partyRefNo"
              class="ll-bform"
            >
              <b-form-input
                id="partyRefNo"
                v-model="account.billToProvince"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="ZIP"
              label-for="numRtn"
              class="ll-bform"
            >
              <b-form-input
                id="numRtn"
                v-model="account.billToPostal"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Country"
              label-for="sortCode"
              class="ll-bform"
            >
              <b-form-input
                id="sortCode"
                v-model="account.billToCountryCode"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Account No"
              label-for="accountNo"
              class="ll-bform"
            >
              <b-form-input
                id="accountNo"
                v-model="account.accountNumber"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Due days"
              label-for="dueDay"
              class="ll-bform"
            >
              <b-form-input
                id="dueDay"
                v-model="account.netDays"
                readonly
              />
            </b-form-group>
          </b-card-body>
        </b-card>
        <b-card
          no-body
          class="card-browser-states ll-c ll-6">
          <b-card-header>
            <b-card-title>Remit To Party</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-form-group
              label="Name"
              label-for="name1"
              class="ll-bform"
            >
              <b-form-input
                id="name1"
                v-model="account.remitToName"
                readonly
              />
            </b-form-group>
            <!-- <b-form-group
              label="Company"
              label-for="company1"
              class="ll-bform"
            >
              <b-form-input
                id="company1"
                v-model="account.orderID"
                readonly
              />
            </b-form-group> -->
            <b-form-group
              label="Address"
              label-for="address1"
              class="ll-bform"
            >
              <b-form-input
                id="address1"
                v-model="account.remitToAddress1"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="City"
              label-for="city1"
              class="ll-bform"
            >
              <b-form-input
                id="city1"
                v-model="account.remitToCity"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Zip"
              label-for="zip1"
              class="ll-bform"
            >
              <b-form-input
                id="zip1"
                v-model="account.remitToPostal"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Country"
              label-for="country1"
              class="ll-bform"
            >
              <b-form-input
                id="country1"
                v-model="account.remitToCountryCode"
                readonly
              />
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BTable,
  BButton,
  // BSidebar,
  BCardHeader, BCardTitle, BCardBody,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormSelect,
  BPagination,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BTable,
    // flatPickr,
    // BSidebar,
    // AppTimeline,
    // AppTimelineItem,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormSelect,
    BPagination,
    BImg,
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      pageOptions: [15, 25, 35],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      searchPID: '',
      borderless: true,
      items: [],
      invoiceFields: [
        { key: 'tkID', label: 'TKID' },
        { key: 'status', label: 'Status' },
        { key: 'invoiceType', label: 'Invoice Type' },
        { key: 'feeType', label: 'Fee Type' },
        { key: 'amount', label: 'Amount' },
      ],
      jobTrack: {
        page: '1',
        size: '20',
        startEventTime: null,
        endEventTime: null,
        statusCode: null,
        orderBy: 'id',
        orderMole: '0',
        tkIDs: '',
      },
      baseInfo: {
        invoiceNo: '',
        status: null,
        createdAt: '',
        quantity: '',
        totalAmount: '',
      },
      account: {
        billToName: '',
        billToCompany: '',
        billToAddress1: '',
        billToCity: '',
        billToProvince: '',
        billToPostal: '',
        billToCountryCode: '',
        accountNumber: '',
        netDays: '',
        remitToName: '',
        remitToCity: '',
        remitToPostal: '',
        remitToCountryCode: '',
      },
      tags: [],
      condition: {
        page: '1',
        size: '15',
        orderBy: 'id',
        // orderMole: 0,
        status: null,
        invoiceType: null,
        beginDate: null,
        endDate: null,
        refNo: null,
        invoiceNo: null,
      },
    }
  },
  created() {},
  mounted() {
    this.getDetails()
    this.getDetails1()
    this.statusTrack()
  },
  methods: {
    // base info
    getDetails() {
      this.condition.invoiceNo = this.$route.params.id
      this.$http.post('invoiceDetail/search', this.condition).then(res => {
        // console.log(res.data.data)
        const info = res.data.data.content
        this.items = info
        const result = res.data.data
        this.page = result.page
        this.size = result.size
        this.totalSize = result.totalSize
        this.totalPage = result.totalPage
      })
    },
    getDetails1() {
      this.$http.get('invoices/getDetail', { params: { id: this.$route.params.pid } }).then(res => {
        // console.log(res.data.data)
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.baseInfo.status = info.status.toString()
          this.baseInfo.invoiceNo = info.invoiceNo
          this.baseInfo.createdAt = info.createdAt
          this.baseInfo.quantity = info.quantity.toString()
          this.baseInfo.totalAmount = info.totalAmount.toString()
          this.account = info.data.account
        }
      })
    },
    // status tracking
    statusTrack() {
    },
    // 根据TKID查询
    handleSearch() {
      if (this.searchPID.trim() !== '') {
        this.condition.refNo = this.searchPID.trim()
        this.getDetails()
      } else {
        this.condition.refNo = null
        this.getDetails()
      }
    },
    handleChangePage(page) {
      console.log(page)
      this.condition.page = page
      this.getDetails()
    },
    handlePageChange(active) {
      console.log(active)
      this.condition.size = active
      this.getDetails()
    },
  },
}
</script>
<style scoped>
.ll-0{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.mb-0{
  font-size: 12px;
}
.ll-1{
  display: flex;
  width: 100%;
}
.ll-title{
  width: 70%;
  /* margin: auto; */
}
.ll-input{
  width: 30%;
}
.ll-p2{
  padding: 0.5rem;
}
.ll-c{
  margin-bottom: 0;
}
.ll-c .card-header{
  font-weight: 500;
  font-size: 1.285rem;
}
.ll-c .card-body .card-title{
  font-weight: normal;
  /* font-size: 14px; */
}
.d-block{
  font-size: 14px;
}
.ll-h .col{
  text-transform: uppercase;
  font-size: 0.857rem;
  letter-spacing: 0.5px;
  font-weight: bold;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  text-align: center;
  width: 100%;
}
.ll-btn .btn-danger, .ll-btn .btn-primary{
  width: 30%;
}
.ll-3{
  margin-top: 10px;
}
.ll-3 div{
  padding: 0;
}
.g-label{
  justify-content: space-between !important;
}
.ll-4{
  height: 80px;
  background-color: unset;
  box-shadow: unset;
}
.ll-4 .card-header{
  font-weight: normal;
  font-size: 12px;
}
.ll-4 .card-title{
  font-weight: 500 !important;
  font-size: 16px;
}
.ll-bform{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ll-6{
  margin-bottom: 0px;
  overflow-y: auto;
}
.ll-trim{
  position: relative;
  left: 40px;
}
.ll-trim1{
  position: relative;
  left: 5px;
}
.ll-badge{
  padding: 5px;
  margin: 5px;
}
.ll-cb{
  margin: 1.5rem;
  margin-top: 0;
}
.ll-icon{
  color: red;
  float: right;
}
.icon-sussess{
  color: #28c76f;
}
.icon-fail{
  color: #ff9f43;
}
.ll-form {
  display: flex;
  justify-content: space-between;
}
.ll-tagBox{
  padding: 2rem 0.5rem;
}
.ll-mrg{
  margin-bottom: 10px;
}
.ll-height{
  height: 780px;
}
.ll-page{
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
<style>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th{
  background-color: #fff;
}
[dir] .table thead th{
  border: none;
  padding: 5px;
}
.d-block{
  font-size: 14px;
}
[dir] .card .card-title {
  margin-bottom: 0.5rem;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 28%;
}
.d-block{
  font-size: 12px;
}
.b-sidebar-header strong{
  width: 100%;
  text-align: center;
}
.table td {
    padding: 0.72rem;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>
